import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Terms &amp; Policy
            </h5>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <h3 className={"mb-3 mt-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Terms of Use
            </h3>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part I – Acceptance of Terms
            </h5>
            <p>
              By accessing and using this website, you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, please do not use this website.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part II – Changes to the Terms
            </h5>
            <p>
              The website owner reserves the right to modify these Terms of Use at any time without prior notice. The latest version of the terms will be posted on this page, and your continued use of the website constitutes acceptance of those changes.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part III – Intellectual Property Rights
            </h5>
            <p>
              All content on this website, including articles, books, videos, images, and other materials, is the intellectual property of the website owner unless otherwise stated. You may not reproduce, distribute, or use any content without express permission from the website owner.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part IV – Use of Content
            </h5>
            <p>
              You are allowed to view and read the content displayed on this website for personal, non-commercial purposes only. Any other use, including copying, modifying, distributing, or publishing any part of this content without prior written consent, is strictly prohibited.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part V – Links to Other Websites
            </h5>
            <p>
              This website may contain links to third-party websites. The website owner is not responsible for the content, privacy policies, or practices of these external sites.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part VI – Limitation of Liability
            </h5>
            <p>
              The website owner is not liable for any damages, losses, or injuries arising from your use of this website or reliance on any information provided here.
            </p>
            <h3 className={"mb-3 mt-4 " + (darkTheme ? "text-white" : "")}>
              Privacy Policy
            </h3>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part I – Introduction
            </h5>
            <p>
              This Privacy Policy explains how information about you is collected, used, and disclosed when you visit this website.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part II – Information We Collect
            </h5>
            <p>
              The website is primarily designed to display content such as articles, books, and public speaking engagements. As such, we do not collect personal information unless you voluntarily provide it, such as through a contact form or email subscription
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part III – Cookies
            </h5>
            <p>
              This website may use cookies to improve user experience. Cookies are small text files stored on your device when you visit the website. You may disable cookies through your browser settings, but this may affect your experience on the site.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part IV – Use of Information
            </h5>
            <p>
              Any personal information you provide will only be used to respond to inquiries, provide updates, or improve the website's content. We will not sell, rent, or share your personal information with third parties without your consent.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part V – Third-Party Links
            </h5>
            <p>
              This website may contain links to other websites. We are not responsible for the privacy practices or the content of such websites, and we encourage you to read the privacy statements of any linked sites.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part VI – Security
            </h5>
            <p>
              We take reasonable precautions to protect your information, but we cannot guarantee the absolute security of any information transmitted to or from the website.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part VII – Changes to This Privacy Policy
            </h5>
            <p>
              We may update this Privacy Policy from time to time. Any changes will be posted on this page, and your continued use of the website constitutes acceptance of those changes.
            </p>
            <h5 className={"text-4 mt-4 " + (darkTheme ? "text-white" : "")}>
              Part VIII – Contact Information
            </h5>
            <p>
              If you have any questions about this Privacy Policy or the Terms of Use, please contact us via the contact form on the website.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
