const filters = {
  ARTICLES: "Articles",
  BOOKS: "Books",
  PUBLIC_SPEAKING: "PublicSpeaking",
  TEACHING: "Teaching"
};

const projectDataTest = [
  {
    title: "Compassion, bureaucrat bashing, and public administration",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    projectInfo:
      "How citizens behave toward public sector workers is crucial for the well-being and performance of workers. Scholars have mainly focused on understanding negative citizen behaviors, such as aggression. We study a positive behavior, namely compassionate behavior. We study real compassionate behavior in the form of writing positive encouragement messages that are distributed to social workers in the field. We test if showing difficulties faced by public sector workers results in citizens writing more encouragement messages. We also test if bureaucrat bashing results in less encouragement messages. Using a preregistered experiment among a representative sample of Canadian citizens (n = 1,264), we find that showing public sector workers’ struggles and imperfections makes citizens almost twice as likely to write an encouragement message. Hence, showing your weakness can be a strength. Bureaucrat bashing, however, has no effect. Results show that citizens can be stimulated to act more positively toward public sector workers.",
    url: {
      name: "www.onlinelibrary.wiley.com",
      link: "https://onlinelibrary.wiley.com/doi/full/10.1111/puar.13485",
    },
    publishingYear: "2022",
    socialLinks: {
      twitter: "",

    },
    thumbImage: "images/projects/bureaucrat.jpg",
    sliderImages: [
    ],
    categories: [filters.ARTICLES],
  },
  {
    title: "Working 9 to 5? A cross-national analysis of public sector worker stereotypes.",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    projectInfo:
      "We present an inductive, citizen-driven approach to identify stereotypes of public sector workers across the United States, Canada, the Netherlands and South Korea (Study 1: n=918; Study 2: n=3,042). Contrary to common negative portrayals, we idetify two positive stereotypes across countries — having job security and serving society; and one neutral/negative stereotype — going home on time. Notably, Americans and Canadians have a more favorable view of public sector workers than the Dutch and South Koreans. This study opens avenues for exploring positive public sector stereotypes and the impact of context on these perceptions.",
    url: {
      name: "www.tandfonline.com",
      link: "https://www.tandfonline.com/doi/full/10.1080/14719037.2023.2254306",
    },
    publishingYear: "2024",
    socialLinks: {
      twitter: "",

    },
    thumbImage: "images/projects/nine-to-five.jpg",
    sliderImages: [
    ],
    categories: [filters.ARTICLES],
  },
  {
    title: "The threat of appearing lazy, inefficient, and slow? Stereotype threat in the public sector.",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    projectInfo:
      "Public employees are stereotyped as lazy, inefficient, and slow. When made aware of such stereotypes, they may experience stereotype threat that impairs their task-performance. Across two pre-registered, large-scale between-subjects experiments (n1 = 1,543; n2 = 1,147), we found that performance in terms of task correctness, processing time, and effort was unaffected by information of negative public employee stereotypes. Our results do not indicate stereotype threat effects for public employees in terms of task-performance. This finding offers valuable theoretical and practical implications for the understanding of public sector stereotypes and public sector reputation.",
    url: {
      name: "www.tandfonline.com",
      link: "https://www.tandfonline.com/doi/full/10.1080/14719037.2023.2229326",
    },
    publishingYear: "2024",
    socialLinks: {
      twitter: "",

    },
    thumbImage: "images/projects/lazy.jpg",
    sliderImages: [
    ],
    categories: [filters.ARTICLES],
  },
  {
    title: "Positive Public Sector Stereotypes and their Impact on Public Service Delivery: An Audit Experiment.",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    projectInfo:
      "There are both negative and positive stereotypes about public sector workers. Most studies focus on negative stereotypes, like the idea that public servants are lazy. We, however, do the opposite. We focus on a positive stereotype: public sector workers are seen as caring and helpful. We test the effects of positive stereotypes on the quality of public service delivery. Using a pre-registered audit experiment in elderly care in the Netherlands and Belgium, we find that activating a pro-social stereotype does not affect the outcome of public service quality in terms of response rate and information provision. However, it does improve the bureaucratic process: public sector workers are friendlier towards citizens. They say around 12 percent more ‘thank you’ in their replies. Moreover, the citizens’ gender affects the response rate: female citizens receive around 10 percent more replies. Concluding, we show that positive stereotyping can improve parts of the quality of public service delivery but not all.",
    url: {
      name: "www.researchgate.net",
      link: "https://www.researchgate.net/profile/Noortje-De-Boer-2/publication/380547399_Positive_Public_Sector_Stereotypes_and_their_Impact_on_Public_Service_Delivery_An_Audit_Experiment/links/66435f533524304153a49690/Positive-Public-Sector-Stereotypes-and-their-Impact-on-Public-Service-Delivery-An-Audit-Experiment.pdf ",
    },
    publishingYear: "2024",
    socialLinks: {
      twitter: "",

    },
    thumbImage: "images/projects/positive.jpg",
    sliderImages: [
    ],
    categories: [filters.ARTICLES],
  },
  {
    title: "Groomers and Trigger-Happy Thugs?: Public Sector Stereotypes of Teachers and Police Officers",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    projectInfo:
      "A study assessing political views, interpersonal trust, and the role of personal characteristics in the (non)support of negative stereotypes about public sector occupations. Study in collaboration..",
    url: {
      name: "-",
      link: "-",
    },
    publishingYear: "Ongoing",
    socialLinks: {
      twitter: "",

    },
    thumbImage: "images/projects/police.jpg",
    sliderImages: [
    ],
    categories: [filters.ARTICLES],
  },
  {
    title: "The identification effect of LGBT+ citizens. A field experiment in elderly care",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    projectInfo:
      "A study testing the (non)discriminatory approach towards LGBT+ citizens in the access towards public services. Study in collaboration..",
    url: {
      name: "-",
      link: "-",
    },
    publishingYear: "Ongoing",
    socialLinks: {
      twitter: "",

    },
    thumbImage: "images/projects/lgbtq.jpg",
    sliderImages: [
    ],
    categories: [filters.ARTICLES],
  },
  {
    title: "The Good, the Bad and the Bureaucrat: Investigating positive and negative public sector worker stereotypes",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    projectInfo:
      "This dissertation challenges the pervasive negative narratives surrounding public sector workers. Dispelling the myth of unilaterally bleak perceptions, through a series of studies with experiments and varied methods, the study reveals a multifaceted realm where positivity intertwines with negativity. Thereby, it presents to you the good, the bad, and the bureaucrat. By understanding the duality of stereotypes and delving into the interplay of macro and micro level factors, this work not only dismantles common assumptions but calls for a re-evaluation of how public sector worker stereotyping is perceived. The research underscores the pivotal role of stereotypes play in citizen- state interactions.This study reveals that positive stereotypes can influence the way public sector workers and citizens interact, leading to a positive change in perceptions and relationships.",
    url: {
      name: "dspace.library.uu.nl",
      link: "https://dspace.library.uu.nl/handle/1874/437584",
    },
    publishingYear: "2024",
    socialLinks: {
      twitter: "",

    },
    thumbImage: "images/projects/Book-cover-png.png",
    sliderImages: [
    ],
    categories: [filters.BOOKS],
  },
  {
    title: "Leadership, Change Science and Organizational Development",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    projectInfo:
      "Interviewed for an episode on a podcast about Leadership, Change Science and Organizational Development in the Netherlands about my research on public servants and citizen-state interactions.",
    url: {
      name: "-",
      link: "-",
    },
    publishingYear: "2024",
    socialLinks: {
      twitter: "",

    },
    thumbImage: "images/projects/public-speaking.jpg",
    sliderImages: [
    ],
    categories: [filters.PUBLIC_SPEAKING],
  },
  {
    title: "European Group of Public Administration (EGPA) Conference",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    location: "Zagreb",
    publishingYear: "2023",
    categories: [filters.PUBLIC_SPEAKING],
    thumbImage: "images/projects/eu.jpg",
  },
  {
    title: "International Research Society for Public Management (IRSPM) Conference",
    briefProjectDescription: "",
    location: "Budapest",
    publishingYear: "2023",
    categories: [filters.PUBLIC_SPEAKING],
    thumbImage: "images/projects/eu.jpg",
  },
  {
    title: "European Group of Public Administration (EGPA) Conference",
    location: "Lisbon",
    publishingYear: "2022",
    categories: [filters.PUBLIC_SPEAKING],
    thumbImage: "images/projects/eu.jpg",
  },
  {
    title: "Public Management Research Association Conference (PMRC)",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    location: "Arizona",
    publishingYear: "2022",
    categories: [filters.PUBLIC_SPEAKING],
    thumbImage: "images/projects/arizona.jpg",
  },
  {
    title: "Netherlands Institute of Governance (NIG) Conference",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    location: "Utrecht",
    publishingYear: "2021",
    categories: [filters.PUBLIC_SPEAKING],
    thumbImage: "images/projects/netherlands-institute-of-governance.jpg",
  },
  {
    title: "European Group of Public Administration (EGPA) Conference",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    location: "Brussels",
    publishingYear: "2021",
    categories: [filters.PUBLIC_SPEAKING],
    thumbImage: "images/projects/eu.jpg",
  },
  {
    title: "Netherlands Institute of Governance (NIG) Conference",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    location: "Live Streamed",
    publishingYear: "2020",
    categories: [filters.PUBLIC_SPEAKING],
    thumbImage: "images/projects/netherlands-institute-of-governance.jpg",
  },
  {
    title: "International Research Society for Public Management (IRSPM)",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    location: "Live Streamed",
    publishingYear: "2020",
    categories: [filters.PUBLIC_SPEAKING],
    thumbImage: "images/projects/eu.jpg",
  },
  {
    title: "Netherlands Institute of Governance (NIG) Conference",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    location: "Amsterdam",
    publishingYear: "2019",
    categories: [filters.PUBLIC_SPEAKING],
    thumbImage: "images/projects/netherlands-institute-of-governance.jpg",
  },
  {
    title: "Teaching Assistant",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    institution: "Utrecht University",
    publishingYear: "2021",
    class: "Comparative Demographic Institution course",
    role: "Teaching Assistant, Philosophy, Politics and Economics (PPE) program",
    categories: [filters.TEACHING],
    thumbImage: "images/projects/public-speaking.jpg",
  },
  {
    title: "Teaching Assistant",
    briefProjectDescription: "Some quick example text to build on the card title and make up the bulk of the card's content.",
    institution: "Utrecht School of Governance",
    publishingYear: "2023",
    class: "Governance and Organization",
    role: "Research on Governance and Organization course, taught four lectures on quantitative research methods. ",
    categories: [filters.TEACHING],
    thumbImage: "images/projects/public-speaking.jpg",
  },

];

const projectsData = [
  {
    title: "Compassion, bureaucrat bashing, and public administration",
    projectInfo:
      "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    client: "Ruby Clinton",
    technologies: "iOS, HTML5, CSS3, PHP, Java",
    industry: "Art & Design",
    date: "July 16, 2019",
    url: {
      name: "www.onlinelibrary.wiley.com",
      link: "https://onlinelibrary.wiley.com/doi/full/10.1111/puar.13485 ",
    },
    socialLinks: {
      twitter: "http://www.twitter.com/",

    },
    thumbImage: "images/projects/project-1.jpg",
    sliderImages: [
      "images/projects/project-1.jpg",
      "images/projects/project-5.jpg",
    ],
    categories: [filters.ARTICLES],
  },
  {
    title: "Project Title 2",
    projectInfo:
      "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    client: "Ruby Clinton",
    technologies: "iOS, HTML5, CSS3, PHP, Java",
    industry: "Art & Design",
    date: "July 16, 2019",
    url: {
      name: "www.example.com",
      link: "https://www.example.com",
    },
    socialLinks: {
      facebook: "http://www.facebook.com/",
      twitter: "http://www.twitter.com/",
      google: "http://www.google.com/",
      instagram: "http://www.instagram.com/",
      mail: "mailto:example@gmail.com",
    },
    thumbImage: "images/projects/project-2.jpg",
    sliderImages: [
      "images/projects/project-2.jpg",
      "images/projects/project-5.jpg",
    ],
    categories: [filters.ARTICLES],
  },
  {
    title: "Project Title 3",
    projectInfo:
      "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
    client: "Ruby Clinton",
    technologies: "iOS, HTML5, CSS3, PHP, Java",
    industry: "Art & Design",
    date: "July 16, 2019",
    url: {
      name: "www.example.com",
      link: "https://www.example.com",
    },
    socialLinks: {
      facebook: "http://www.facebook.com/",
      twitter: "http://www.twitter.com/",
      google: "http://www.google.com/",
      instagram: "http://www.instagram.com/",
      mail: "mailto:example@gmail.com",
    },
    thumbImage: "images/projects/project-3.jpg",
    sliderImages: [
      "images/projects/project-3.jpg",
      "images/projects/project-5.jpg",
    ],
    categories: [filters.PUBLIC_SPEAKING],
  }
];

export { projectsData, projectDataTest, filters };
