import React from "react";
import resumeFile from "../documents/resume.pdf";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Know Me More
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start ">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              Hi! I'm <span className="text-primary">Gabriela Szydlowski,</span> a behavioral scientist.
            </h2>
            <div className="about-text about-me">
            <p className={darkTheme ? "text-white-50" : ""}>
              I have a deep passion for exploring the "why" behind human behavior. My curiosity spans a wide array of topics, from mental health, social and cognitive psychology, public policy, habits, to behavioral interventions and the intricate dynamics of human interactions.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              My academic journey is as diverse as my interests. I hold a Bachelor’s degree in Psychology and Anthropology, a Master’s degree in research in Clinical and Developmental Psychology, and a PhD in Public Administration and Organizational Sciences. This interdisciplinary background fuels my enthusiasm for tackling complex behavioral puzzles and crafting evidence-based solutions that drive positive change.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              Throughout my career, I’ve had the privilege of working on research projects across Canada, the Netherlands, South Korea, and the United States. My work has primarily focused on Behavioral Public Administration, where I dived into understanding how stereotypes, particularly those surrounding public sector workers, impact behavior and citizen-state interactions. Are public servants really perceived as laze, inefficient and overpaid? My research seeks to answer these questions, debunk myths, identify underlying factors, and design and experimentally test behavioral interventions that improve public service delivery and performance, decrease negative stereotypes, and enhance the relationship between citizens and the state.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              My work includes a wide range of research methodologies, including descriptive and experimental studies, mixed-methods and quantitative approaches, cross-sectional analyses, surveys and survey experiments, as well as field experiments. I take great pleasure in brainstorming research methods and study designs, always striving to develop creative and efficient ways to test new research questions.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              Beyond my research, I’m passionate about bridging the gap between theory and practice. I thrive on performing research that not only answer critical questions but also offer actionable insights for real-world problems. I’m committed to making a meaningful, positive societal impact.
            </p>
            <p className={darkTheme ? "text-white-50" : ""}>
              I bring to the table a diverse skill set, a dedicated work ethic, enthusiasm, and an unwavering commitment to understanding and improving the world around us. Let’s connect and explore how we can make a difference together!
            </p>
            </div>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Gabriela Szydlowski
                </li>
                <li>
                  <span className="fw-600 me-2">Spoken languages:</span>English, French, Polish
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Montreal,
                  Canada
                </li>
              </ul>
              <a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
              >
                Download CV
              </a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
{/*           <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>10</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>250</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>650</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>38</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Get Awards
                </p>
              </div>
            </div>
          </div> */}
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
