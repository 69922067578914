const educationDetails = [
  {
    yearRange: "2013 - 2017",
    title: "Bachelor of Arts",
    place: "McGill University",
    country: "Canada",
    city: "Montreal",
    specializations: [
      "Major in Psychology",
      "Major in Anthropology",
      "Minor in Behavioral Science"
    ]
  },
  {
    yearRange: "2017 - 2019",
    title: "Master of Science in Research in Developmental and Clinical Psychopathology",
    place: "Vrije Universiteit",
    country: "Netherlands",
    city: "Amsterdam",
    specializations:[]
  },
  {
    yearRange: "2019 - 2024",
    title: "Doctor of Philosophy in Public Administration and Organizational Science",
    place: "Utrecht University",
    country: "Netherlands",
    city: "Utrecht",
    specializations: []
  },
];

const professionalServiceDetails = [
  {
    yearRange: "2012 - 2013",
    title: "Volunteer",
    place: "CHUM",
    desccription: [
      "Providing moral support and care for geriatric department patients.",
      "Provided logistical and organizational support for the development of recreational program activities for geriatric department patients."
    ]
  },
  {
    yearRange: "2013 - 2017",
    title: "Volunteer",
    place: "Federation of inhabitants OSBL of Montreal (FOHM)",
    desccription: [
      "Provided organizational, administrative, and logistical support.",
      "Support for women victim of domestic violence, social reinsertion, and rehabilitation for the homeless.",
      "Assisted directors in the development of social programs and initiatives."
    ]
  },
  {
    yearRange: "2014",
    title: "Psychiatric Department",
    place: "Hajnowka General Hospital, POLAND",
    desccription: [
      "Observation, general assistance for psychiatrists and clinical psychologists.",
      "Assisted psychiatrists and clinical psychologists in ongoing treatments, and cognitive therapies.",
      "Provided moral support and care for the patients."
    ]
  },
  {
    yearRange: "2019",
    title: "OV - Coach Training Program",
    place: "Vrije Universiteit",
    desccription: [
      "Creation of a workshop and training program for volunteer coaches on anxiety.",
      "Assembling a presentation and relevant information on what anxiety is, identifying signs of a panic attack and how to help someone experiencing it.",
      "Collaborative team work project."
    ]
  },
  {
    yearRange: "2020 - 2021",
    title: "Master's Buddy Program",
    place: "Utrecht University",
    desccription: [
      "Part of founding team – program to connect Master’s students with scholars and provide help in applying for PhD positions.",
    ]
  },
  {
    yearRange: "2020 - 2022",
    title: "Here's My Two-Cents",
    place: "Utrecht University",
    desccription: [
      "Developed and implemented a series of online talks to provide network opportunities and connect younger researchers and academics to scholars during the COVID - 19 pandemic.",
      "Brainstormed and organized talks on various relevant academic topics, sought out and invited guest speakers."
    ]
  },
  {
    yearRange: "2024",
    title: "Volunteer",
    place: "Federation of inhabitants OSBL of Montreal (FOHM)",
    desccription: [
      "Volunteer work.",
      "Support for the board of directors of a non-profit organization: for the development of new social housing buildings for single women with multiple issues, researching provincial and federal funding programs, and analysis of needs of women in housing."
    ]
  },
];

const experienceDetails = [
  {
    yearRange: "2012 - 2014",
    title: "Civil Servant",
    place: "Rental Board of Quebec",
    desccription: [
      "Assistance with clerical duties including filing, data base entries, correspondence, monitoring of applicants and files, archiving, customer service, executive and administrative support.",
    ]
  },
  {
    yearRange: "2019",
    title: "Research Assistant in Developmental and Clinical Psychopathology",
    place: "Vrije Universiteit",
    desccription: [
      "Conducted data cleaning, data analysis and integrated large datasets from diverse samples."
    ]
  },
  {
    yearRange: "2019 - 2023",
    title: "Behavioral Science Researcher",
    place: "Utrecht University",
    desccription: [
      "Organized, cleaned, and analyzed extensive datasets (samples of 3500 participants).",
      "Lead the collaboration of five international scientific journal articles, and team member of two other research projects.",
      "Managed a team of nine members, five research projects, and a budget of 800 000 euros (1 200 000 CAD).",
      "Integrated insights from cross-disciplinary research (Psychology, Economics, Communications and Media, Public Administration and Policy, Political Science, Sociology, Behavioral Sciences), employing various research methods including field and survey experiments.",
"Conducted literature reviews, research designs, questionnaire development, statistical analyses, and project management.",
"Presented the design and results of my studies at national and international conferences.",
      "Independently collaborated with international colleagues to conduct research.",
      "Conducted academic lectures and seminars (class size of 30 students).",
      "Managed research impact in national news.",
    ]
  }
];

const skills = [
  {
    name: "Web Design",
    percent: 65,
  },
  {
    name: "HTML/CSS",
    percent: 95,
  },
  {
    name: "JavaScript",
    percent: 80,
  },
  {
    name: "React JS",
    percent: 70,
  },
  {
    name: "Angular Js",
    percent: 60,
  },
  {
    name: "Bootstrap",
    percent: 99,
  },
];

export { experienceDetails, educationDetails, skills, professionalServiceDetails };
